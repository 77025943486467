<template>
  <div>
    <b-row class="m-0">
      <b-col class="content-header" cols="12">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchProduct"
            v-model="searchProduct"
            placeholder="Rechercher un produit"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="popupCreateProducts('')"
            class="d-flex px-1 mr-1"
          >
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span>
          </b-button>
          <b-dropdown
            class="bg-outline-primary btn-group-icon"
            variant="outline-primary"
          >
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item class="w-full" @click="exportDataCSV"
              >Exporter en CSV</b-dropdown-item
            >
            <b-dropdown-item class="w-full" @click="exportDataXLS"
              >Exporter en XLS</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingProductsList">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{ "Chargement en cours de la liste des produits..." }}
        </div>
      </div>
      <ag-grid-vue
        v-else
        style="width: 100%; height: 100%"
        class="ag-theme-material"
        :rowData="gridData"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </div>
    <popup-products ref="popupProducts" />
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";

import {
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import PopupProducts from "@/components/product/PopupProducts";

export default {
  components: {
    BButton,
    AgGridVue,
    PopupProducts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filter: false,
      sidebar: false,
      activeSidebarProduct: false,
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
      },
      gridApi: null,
      columnApi: null,
      searchProduct: "",
    };
  },
  computed: {
    ...mapGetters([
      "productsList",
      "isLoadingProductsList",
      "isOpenSidebarProduct",
    ]),
    gridData() {
      return this.$store.getters.productsList;
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "label",
        headerName: "Libellé",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "publicPrice",
        headerName: "Prix public",
        resizable: true,
        sortable: true,
        minWidth: 160,
        volatile: true,
        cellRenderer: (params) => {
          return params.value + " €";
        },
      },
      {
        field: "netPrice",
        headerName: "Prix net",
        resizable: true,
        sortable: true,
        minWidth: 160,
        volatile: true,
        cellRenderer: (params) => {
          return params.value + " €";
        },
      },
      {
        field: "unit",
        headerName: "Unité",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
    ];
  },
  created() {
    this.fetchProductsList();
  },
  methods: {
    ...mapActions(["fetchProductsList", "deleteProduct"]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event) {
      this.popupCreateProducts(event.data.id);
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchProduct);
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    popupCreateProducts(id) {
      this.$bvModal.show("popup-products");
      this.$refs["popupProducts"].popupCreateProductEvent(id);
    },
  },
};
</script>
